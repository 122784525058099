<template>
  <div id="hierarchy" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <form @submit.prevent="passes(submit)">
          <div class="columns is-multiline">
            <div class="column is-8 is-offset-2">
              <div
                class="column is-12"
                v-for="schoolClass in schoolClasses"
                :key="schoolClass.id"
              >
                <div class="columns is-multiline">
                  <div class="column is-4">{{ schoolClass.name }}</div>
                  <div class="column is-8">
                    <SdInput
                      type="text"
                      rules="required"
                      v-model="schoolClass.hierarchy"
                    />
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <button
                  type="submit"
                  class="button btn-120 is-primary is-capitalized is-pulled-right"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'

export default {
  name: 'student',
  data() {
    return {
      schoolId: null,
      pageTitle: 'School Class Hierarchy',
      schoolClasses: [],
    }
  },
  apollo: {
    schoolClasses: {
      query: SCHOOL_CLASSES,
      variables() {
        return { schoolId: parseInt(this.$route.params.school_id) }
      },
    },
  },
  methods: {
    submit() {
      const data = this.schoolClasses.map((schoolClass) => {
        return {
          id: schoolClass.id,
          hierarchy: schoolClass.hierarchy,
          created_at: schoolClass.createdAt,
          updated_at: schoolClass.updatedAt,
        }
      })

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateSchoolClassHierarchy($data: JSON!) {
              updateSchoolClassHierarchy(input: { data: $data }) {
                errors
              }
            }
          `,
          variables: {
            data: data,
          },
        })
        .then((response) => {
          if (response.data.updateSchoolClassHierarchy.errors.length === 0) {
            this.closeModal()

            this.$buefy.notification.open({
              duration: 5000,
              message: 'Successfully updated',
              position: 'is-top',
              type: 'is-success',
              hasIcon: true,
            })

            this.$router.push(`/school/${this.schoolId}/school_classes`)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)

    this.$store.commit('setPageTitle', this.pageTitle)

    this.$store.commit('setSubMenus', [
      {
        name: 'School Classes',
        route: `/school/${this.schoolId}/school_classes`,
      },
    ])
  },
}
</script>
